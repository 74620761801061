<template>
  <div class="content-container pt-0">
    <div class="landing-header">
        <img class="img-center" src="../assets/images/landing-banner.png" alt="">
    </div>

    <div class="landing-header-title align-end gray-100">
      <div class="container flex flex-col flex-auto mlr-20 max-w1440">
        <h1 class="xl">Philosophy <br>&amp; Standards</h1>
        <p class="mt-0">
          The design philosophy and general principles of the Ohio Design System.
        </p>
      </div>
    </div>

    <!-- <section>
      <div class="content-band mb-10 mt-20">

        
      </div>
    </section> -->

    <section>
      <div class="content-band mt-10 flex-grid gap-20">
        <NewTile
          v-for="(element, index) in tileData"
          :key="index"
          :tile="element"
          page="philosophy-standards"
        />
      </div>
    </section>
  </div>
</template>

<script>
import NewTile from "../components/NewTile";
export default {
  name: "PhilosophyStandards",
  data: function() {
    return {
      tileData: [
        {
          icon: "experience-pillars",
          title: "Experience Pillars",
          description:
            "Guidelines for ensuring state resources and information are accessible to every Ohioan.",
        },
        {
          icon: "experience-principles",
          title: "Experience Principles",
          description:
            "Detailing the Ohio Design System’s design approach and priorities for creating better experiences.",
        },
        {
          icon: "accessibility-standards",
          title: "Accessibility Standards",
          description:
            "Guidelines for building digital products that all people can use.",
        },
        {
          icon: "terms-&-definitions",
          title: "Terms & Definitions",
          description:
            "Establishing a shared vocabulary to encourage collaboration and improve communication.",
        },
      ],
    };
  },
  components: {
    NewTile,
  },
};
</script>
